import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SimpleAccordion() {
  return (
    <div style={{height:'76vh'}} >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿Puedo pagar con Transbank?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sí, aceptamos Transbank como medio de pago.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿Despachan a regiones y domicilios?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sí, despachamos a domicilio y a regiones mediante Chilexpress.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿Cuáles son los datos bancarios para transferir?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Cuenta corriente del Banco Santander Nº 7160162-5 <br/>
          Nombre: Importadora Tectrova SPA<br/>
          RUT: 76.735.114-3
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{fontWeight:'600'}}>¿Emiten boleta o factura?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sí, todas las ventas son con boleta o factura y los precios son IVA incluido.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Skeleton, Avatar, Box, Button, IconButton, Snackbar, SnackbarContent } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ProductImage from './ProductImage';
import { useEffect } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useContext } from 'react';
import { ThemeContext } from '../context';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const Image = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});

const ProductPage = ({ avatarSource, imageSrc, title, description, manufacturer, model, price, availability, imageList }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [avatarLoaded, setAvatarLoaded] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const theme = useContext(ThemeContext);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };
  const handleAvatarLoad = () => {
    // //console.log('AVATAR LOADED')
    setAvatarLoaded(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const handleBuyClick = () => {
    setSnackBarOpen(true);
    //console.log('BUY CLICKED');
    theme.dispatch({ type: 'ADD_TO_CART', payload: { title, price, imageSrc } });
  }

  return (
    <React.Fragment>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <SnackbarContent
          message={
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" align="center">
                Producto agregado
              </Typography>
              <ShoppingBasketIcon style={{ marginLeft: '0.5em' }} />
            </Box>
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Snackbar>
      <Grid container sx={{ padding: 3 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom sx={{ display: { xs: 'block', md: 'none' } }}>
            {title}
          </Typography>
          {!isImageLoaded && <Skeleton variant="rectangular" width="100%" height={'auto'} />}
          <ProductImage imageSrc={imageSrc} imageList={imageList} />
        </Grid>
        <Grid item xs={12} md={6} paddingLeft={2}>
          <Typography variant="h4" gutterBottom sx={{ display: { xs: 'none', md: 'block' } }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ marginTop: { xs: '2vh', md: 0 } }}>
            <Box style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              {manufacturer === "Brinno" && (
                <Avatar src={avatarSource} sx={{ width: 50, height: 50, display: avatarLoaded ? 'block' : 'none' }} imgProps={{ onLoad: handleAvatarLoad }} />
              )}
              {!avatarLoaded && manufacturer === "Brinno" && <Skeleton variant="circular" width={50} height={50} />}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {manufacturer === "Brinno" && <Typography><span style={{ fontWeight: 'bold' }}>Marca:</span> {manufacturer}</Typography>}
                <Typography component={'span'} ><span style={{ fontWeight: 'bold' }}>Modelo:</span> {model}</Typography>
              </Box>
            </Box>
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <span style={{ fontWeight: 'bold' }}> Precio: </span>{price} IVA incluido.
          </Typography>
          <Typography variant="subtitle1" color={availability === 'En Stock' ? 'success' : 'error'} gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Disponibilidad:</span> {availability}
            {availability === 'En Stock' ?
              <CheckCircleIcon style={{ verticalAlign: 'middle', marginLeft: 5, fontSize: 17, color: 'green' }} /> :
              <HighlightOffIcon style={{ verticalAlign: 'middle', marginLeft: 5, fontSize: 17, color: 'red' }} />
            }
          </Typography>
          <Button onClick={handleBuyClick} variant='contained' color="primary" size="large" sx={{ marginTop: 2 }}>
            <ShoppingCartIcon />
            <Typography>
              Comprar
            </Typography>
          </Button>
          <Typography variant="body1" gutterBottom>
            <span style={{ fontWeight: 'bold' }}>Descripción:</span> {description}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
  

};

export default ProductPage;

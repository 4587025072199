import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {Typography } from "@mui/material";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import emailjs from '@emailjs/browser';


const FormContainer = styled("div")({
  display: "flex",
  justifyContent: "center", // Center along horizontal axis
});

const Form = styled("form")({
  width: "50%",
});

const validatePhone = (value) => {
    const regex = /^(\+56[239])?\d{8}$|^(\+56[239])?\d{11}$|^\+56[239]\d{12}$/;
    return regex.test(value);
  };
  
  const sendEmail = (formData) => {
    //console.log('sendEmail TRIGGERED')
    
    emailjs.sendForm('service_a8t8qq6', 'template_iyulkwr', formData, 'Ktbv8ySoQFCO3oL3Q')
  .then((result) => {
      //console.log('(result: ', result.text);
      // setDone(true);
  }, (error) => {
      //console.log(error.text);
  });
} 
  

const ContactForm = ({setSnackBarOpen}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  //username subject email message
  const [formData, setFormData] = useState({
    name: "",
    message: "",
    email: "",
    company: "",
    phone: "",
  });
  
  const [phoneError, setPhoneError] = useState(false)
  const [emailError , setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)  
  const [phoneHelp, setPhoneHelp] = useState(false)
  const [sent , setSent]  = useState(false)
  const formRef = useRef();  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(name === 'phone' && !phoneError ){
      setPhoneHelp('Empezando con')
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    //console.log(formData)
  };
  

  const handleSubmitv1 = (event) => {
    event.preventDefault();
    //console.log('ENVIANDO UN MAIL =)')
    let templateParams = {
      to_name: 'TECTROVA',
      to_email: 'contacto@tectrova.cl', 
      username: formData.name,
      message: formData.message,

    };
  
    emailjs.sendForm('service_tpxlard', 'template_iyulkwr', formRef.current, 'Ktbv8ySoQFCO3oL3Q')
      .then((result) => {
        //console.log('result.text: ', result.text);
        if(result.text == 'OK'){
          setSent(true)
          setSnackBarOpen(true)
          setFormData({})
        }
        // setDone(true);
      })
      .catch((error) => {
        //console.log(error.text);
      });
  };
  

  const handleBlurPhone = () => {
    setPhoneHelp(false)
    setPhoneError(!validatePhone(formData.phone))    
  }

  const handleBlurEmail = () => {
    setEmailError(!isEmail(formData.email))
  }

  const handleBlurName = () => {
    setNameError(formData.name.length < 5)
  }

  function isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
// //console.log('name error: ', nameError)
  return (
    <FormContainer>        
      <Form sx={{flex: {xs:1, md:'none'}}} ref={formRef} onSubmit={handleSubmitv1} >
        {sent && <Typography sx={{textAlign:'center', fontSize:20, fontWeight:'bold'}} >Gracias por su mensaje.</Typography>}
        <div style={{height:'75vh'}}>
          <TextField
            required
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            color={formData.name.length > 5 && 'success'}
            focused={formData.name.length > 0}
            error={nameError}
            helperText={nameError && 'Debe ingresar un nombre'}
            onBlur={handleBlurName}
            disabled={sent}
            
          />
          <TextField
            required
            label="Teléfono"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            error={phoneError}
            helperText={phoneError ? 'Debe ser un número chileno' : phoneHelp ? phoneHelp : null}
            onBlur={handleBlurPhone} 
            color={validatePhone(formData.phone) && 'success'}
            focused={formData.phone.length > 0}
            disabled={sent}

                     
          />
          <TextField
            required
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            type="email"
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            color={isEmail(formData.email) ? 'success' : 'warning'}
            focused={formData.email.length > 0}
            error={emailError}
            helperText={emailError && 'Debes ingresar una email válido.'}
            onBlur={handleBlurEmail}
            disabled={sent}
          />
          <TextField
            label="Empresa"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            disabled={sent}
          />
          <TextField
            required
            label="Mensaje"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            multiline
            rows={4}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            disabled={sent}
          />
        <Button variant="contained" color="primary" 
        style={sent? {background : 'linear-gradient(to right bottom, #b7cbb2, #47bf73)'} : {background: 'linear-gradient(to right bottom, #CBC1B2, #C6A675)'}}
        onClick={handleSubmitv1}
        disabled = {sent}
        >
          {
            sent?
            'Mensaje Enviado'
            :
            'Enviar mensaje'
          }
        </Button>
        </div>
        {/* <CircularIntegration handleSubmit={(event) => handleSubmitv1(event)} errors={nameError || phoneError || emailError} /> */}
      </Form>
      
    </FormContainer>
  );
};

export default ContactForm;

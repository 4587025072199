import styled from '@emotion/styled';
import { Box, Paper, Skeleton, } from '@mui/material';
import React, { useState } from 'react';
import SmallImage from './SmallImage';

const Image = styled('img')({
  maxWidth: '100%',
  // height: {xs:'1vh', md:'1vh'},
  objectFit:'contain',
  
  
  
});

const ProductImage = ({ imageSrc, title, imageList }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [bigImage, setBigImage] = useState(imageList[0]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageClick = (item) => {
    setBigImage(item);
  };

  return (
    <Box>
      <Paper elevation={5} style={{ height: '70vh',  borderRadius: 20, marginBottom: '2vh', overflow:'hidden' }}>
        {!isImageLoaded && <Skeleton variant='rectangular' width="100%" height={'100%'} />}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Image src={bigImage} alt={title} onLoad={handleImageLoad} style={{ borderRadius: 10, display: isImageLoaded ? 'block' : 'none', height: '100%', width:'100%', objectFit: 'contain' }} />
        </div>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {imageList?.filter((item, index) => item !== bigImage).map((item, index) => (
          <Paper
            elevation={5}
            sx={{
              borderRadius: 2,
              cursor: 'pointer',
              transition: 'transform 0.3s',
              transform: bigImage === item ? 'scale(1.1)' : 'none',
              overflow:'hidden',
              display:'flex',
              marginX:1
            }}
            key={index}
            onClick={() => handleImageClick(item)}
          >
            <SmallImage src={item} alt={title} style={{  borderRadius: 2, objectFit: 'contain', width:{xs:'10vh', md:'14vh'}, height:{xs:'10vw', md:'14vw'}     }} />
          </Paper>
        ))}
      </Box>
    </Box>

  );
};

export default ProductImage;

import { Box } from '@mui/material'
import React from 'react'
import Article from './Article'

const products = [
    {
      id:10,
      title: 'Brinno ASP1000-P',
      description: 'Panel Solar para cámaras Brinno.',
      image: require('../assets/ASP1000.jpg'),
      price: 595000
    },
    {
      id:9,
      title: 'Tascam DR-40X',
      description: 'Grabadora portátil',
      image: require('../assets/dr40x.jpg'),
      price: 260000
    },
    {
        id:0,
        title: 'Brinno BCC2000 Plus',
        description: 'Kit de construcción.',
        image: require('../assets/bcc200plus.jpg'),
        price: 1599000
    },
    {
        id:1,
        title: 'Cámara Timelapse TL2300',
        description: 'Excelente cámara rápida con gran duración de batería y resistente al agua y polvo.',
        image: require('../assets/tl2300.jpg'),
        price: 268100
    },
    {
        id:2,
        title: 'Brinno TLC130',
        description: 'Batería de larga duración, aplicación de edición de video WiFi/BL, compatible con iOS.',
        image: require('../assets/tlc130.jpg'),
        price: 561210
    },
    {
        id:3,
        title: 'Brinno BCC200',
        description: 'Kit de construcción. Duración de bateria de 80 días, sensor HDR y modo nocturno',
        image: require('../assets/tlc200.jpg'),
        price: 518529,
    },
    {
        id:0,
        title: 'Brinno BCC2000',
        description: 'Kit construcción y cámara de lapso de tiempo al aire libre',
        image: require('../assets/BCC2OOO.jpg'),
        price: 1289000
    },
    {
        id: 5,
        title: "Brinno BCC300",
        image: require("../assets/bcc300.jpg"),
        price: '$663.100',
        description: 'Kit de último modelo para exteriores, con soporte de pared, duración de batería extendida.',        
      },
      {
        id: 6,
        title: "Brinno AFB1000",
        model: "AFB1000",
        image: require("../assets/AFB1000.jpg"),
        price: '$226.100',
        description: "Kit extensor de cámara, aumenta tu alcance y amplía las capacidades de tu BCC2000."          
        ,
        imageList: [require("../assets/AFB10002.jpg"), require("../assets/AFB10003.jpg"), require("../assets/AFB10004.jpg"), require("../assets/AFB10005.jpg"), ]
      },
      {
        id: 7,
        title: "Brinno ATH120",
        model: "ATH120",
        image: require("../assets/ATH120.jpg"),
        price: '$75.000',
        description: "Carcasa para cámara resistente a la intemperie, para BCC200."          
        ,
        imageList: [require("../assets/ATH120.jpg"), require("../assets/ATH1202.jpg"), require("../assets/ATH1203.jpg"), require("../assets/ATH1204.jpg"), ]
      },
      {
        id: 8,
        title: "Brinno SHC500",
        model: "SHC500",
        image: require("../assets/SHC500.jpg"),
        price: '$150.000',
        description: "Cámara para puerta de fácil de instalación en mirillas existentes. ",
        imageList: [require("../assets/SHC500.jpg"), require("../assets/SHC5002.jpg"), require("../assets/SHC5003.jpg"), require("../assets/SHC5004.jpg"), require("../assets/SHC5005.jpg"), ]
      }

]

function FeaturedProducts() {
  return (
    <div style={{}}>       
        <Box sx={{display:'flex',  flexWrap:'wrap', gap: 2, justifyContent:'center'  }} >
            {
                products.map((item, index) => (
                    <Article key={index} title={item.title} description={item.description} image={item.image} />
                ) )
            }            
        </Box>
    </div>
  )
}

export default FeaturedProducts
import { AppBar, Box, Fab, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { styled,  } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useNavigate } from 'react-router-dom';

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
    opacity:0.8
  });

const Image = styled('img')({
// maxWidth: '100%',
height: '53px',

});

function Footer() {
    const navigate = useNavigate();

  return (
    <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0 }} style={{ background: 'linear-gradient(to right bottom, #CBC1B2, #C6A675)' }}>
  <Toolbar>
    <IconButton color="inherit" aria-label="open drawer" onClick={() => navigate('/contacto')} sx={{display:{xs:'none', md:'block'}}}>
      <SendIcon />
        <Typography>
            Contáctanos
        </Typography>
    </IconButton>
    <Grid container justifyContent="center" alignItems="center" sx={{  }}>
      <Grid item>
        <Image sx={{display:{xs: 'none', md:'initial'}}} src={require('../assets/flow1.jpg')} />
      </Grid>
      <Grid item>
        <Image src={require('../assets/flow2.jpg')} />
      </Grid>
    </Grid>
    {/* <StyledFab color="primary" aria-label="add">
      <HomeIcon />
    </StyledFab> */}
    <Box sx={{ flexGrow: 1,  }} />
    <IconButton color="inherit">
        <Typography sx={{fontSize:{xs:8, md:14}}}>
            Tectrova 2024
        </Typography>
      <CopyrightIcon sx={{fontSize:{xs:8, md:14}}} />
    </IconButton>
    {/* <IconButton color="inherit">
      <MoreIcon />
    </IconButton> */}
  </Toolbar>
</AppBar>
  )
}

export default Footer
import { Typography } from '@mui/material'
import React from 'react'
import CartForm from './CartForm'

const Step1 = ({formData, setFormData, setDisabledNext}) => {
 
  return (
    <div>
        <CartForm formData={formData} setFormData={setFormData} setDisabledNext={setDisabledNext}/>
        
    </div>
  )
}

export default Step1

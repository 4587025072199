import React, { useState } from 'react'
import Navbar from "../components/Navbar";

import { Box, Typography } from '@mui/material'
import HorizontalLinearStepper from '../components/Stepper';
const CartScreen = () => {
    const [formData, setFormData] = useState({
        name: "",
        // message: "",
        email: "",
        company: "",
        phone: "",
      });
  return (
    <Box height={'100vh'} >
        <Navbar />
        <Typography sx={{textAlign:'center', fontSize:30, fontWeight:100}} >Carro de compras</Typography>      
        <HorizontalLinearStepper formData={formData} setFormData={setFormData} />
      
    </Box>
  )
}

export default CartScreen

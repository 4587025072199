import { createContext, useReducer } from "react";

export const ThemeContext = createContext();

const INITIAL_STATE = { darkMode: false, items: [] };

const themeReducer = (state, action) => {
    switch(action.type){
        case 'TOGGLE':
            return({
                darkMode: !state.darkMode,
                items: state.items
            });
        case 'ADD_TO_CART':
            return({
                items: [...state.items, action.payload],
                darkMode: state.darkMode
            });
        case 'REMOVE_FROM_CART':
            const indexToRemove = state.items.findIndex(item => item.title === action.payload.title);
            if (indexToRemove !== -1) {
                const updatedItems = [...state.items.slice(0, indexToRemove), ...state.items.slice(indexToRemove + 1)];
                return {
                    ...state,
                    items: updatedItems
                };
            }
        case 'CLEAR_CART':
            return({
                items: [],
                darkMode: state.darkMode
            });

        default:
            return state;
    }
}

export const ThemeProvider = (props) => {
    const [state, dispatch] = useReducer(themeReducer, INITIAL_STATE);
    return(
        <ThemeContext.Provider value={{state, dispatch}}>
            {props.children}
        </ThemeContext.Provider>
    );
}
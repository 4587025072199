import { Skeleton, styled } from '@mui/material';
import React, { useState } from 'react'

const Image = styled('img')({
    maxWidth: '100%',
    // height: {xs:'1vh', md:'1vh'},
    objectFit:'contain',
  });

const SmallImage = ({src, style}) => {
    const [imageLoading, setImageLoading] = useState(false)      
  return (    
        imageLoading ?
        <Skeleton variant='rectangular' sx={{width:'20vw', height:'20vh'}} />
        :
        <Image src={src} onLoadStart={() => setImageLoading(true)} onLoad={() => setImageLoading(false)} style={style} />
    )
}

export default SmallImage
import React from 'react'
import SimpleAccordion from '../components/SimpleAccordion'
import Navbar from '../components/Navbar'
import { Typography } from '@mui/material'
import Footer from '../components/Footer'

function FAQScreen() {
  return (
    <div>
      <Navbar />
      <Typography sx={{textAlign:'center', fontSize:30, fontWeight:100}} >Preguntas Frecuentes</Typography>
      <SimpleAccordion />
      <Footer/>
    </div>
  )
}

export default FAQScreen
import { Typography } from '@mui/material'
import React from 'react'
import FeaturedProducts from '../components/FeaturedProducts'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SliderBanner from '../components/SliderBanner'


function HomeScreen() {
  return (
    <div>
        <Navbar />
        <SliderBanner />
        <Typography style={{textAlign:'center', fontSize:30, fontWeight:'300'}}>Nuestros productos</Typography>
        
        <FeaturedProducts />
        <Footer />
    </div>
  )
}

export default HomeScreen
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Skeleton } from "@mui/material";
import { useState } from "react";

export default function Example(props) {
  var items = [
    {
      id: 0,
      title: "Brinno BCC2000 Plus",
      description: "Kit de construcción.",
      image: require("../assets/bcc200plus.jpg"),
      price: 1250000,
    },
    {
      id: 1,
      title: "Cámara Timelapse TL2300",
      description:
        'Excelente cámara rápida con gran duración de batería y resistente al agua y polvo (IP66)* Pantalla LCD: 2,4 "TFT LCD* Lente: Angulo de visión: 110 °.',
      image: require("../assets/tl2300.jpg"),
      price: 268100,
    },
    {
      id: 2,
      title: "Brinno TLC130",
      description:
        "Batería de larga duración, aplicación de edición de video WiFi/BL, video de pasos, foto, HD 1080p, IPX4 resistente al agua. Compatible con  iOS Easy Connect.",
      image: require("../assets/tlc130.jpg"),
      price: 561210,
    },
    {
      id: 3,
      title: "Brinno BCC200",
      description:
        "Kit de construcción. Duración de bateria de 80 días, sensor HDR y modo nocturno",
      image: require("../assets/tlc200.jpg"),
      price: 518529,
    },
    // {
    //   id: 0,
    //   title: "Brinno BCC2000 Plus",
    //   description: "Cámara .",
    //   image: require("../assets/bcc2000.jpg"),
    //   price: 1250000,
    // },
  ];

  return (
    <Carousel
      prev={(prev, active) =>
        console.log(`we left ${prev}, and are now at ${active}`)
      }
      // fullHeightHover={true}
      
      navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
            backgroundColor: 'cornflowerblue',
            borderRadius: 0
        }
    }}
    indicatorContainerProps={{style:{marginTop:20}}}
    swipe={true} 
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  const [loadingImage, setLoadingImage] = useState(false);
  return (
    <Paper elevation={0}>
      <h2 style={{ textAlign: "center" }}>{props.item.title}</h2>
      <p style={{ textAlign: "center" }}>{props.item.description}</p>
      {/* <img src={props.item.image} alt="BCC2000" style={{ width: '300px', height: 'auto', textAlign:'center' }} /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        {loadingImage && (
          <Skeleton
            variant="rectangular"
            width={500}
            height={500}
            animation="pulse"
          />
        )}
        <img
          src={props.item.image}
          alt="Cámara Timelapse"
          style={
            loadingImage
              ? { display: "none" }
              : { width: "500px", height: "450px", objectFit:'contain', marginBottom:0 }
          }
          onLoad={() => setLoadingImage(false)}
        />
      </div>

      {/* 
            <Button style={{marginBottom:0}} >
                Ver detalles
            </Button> */}
    </Paper>
  );
}

import React, { useContext } from 'react';
import './toggle.css';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ThemeContext } from '../../context';
import { Box, FormControlLabel, Switch } from '@mui/material';

const Toggle = ({setMode, mode}) => {
    const theme = useContext(ThemeContext);

    const handleClick = () => {
      theme.dispatch({type:"TOGGLE"});
      setMode(mode === 'light'? 'dark': 'light')
    }
    // //console.log('THEME: ', theme)

  return (
        <Box sx={{position: 'fixed',  right:'5px', zIndex: 100, marginTop:{xs: 8, md:0} }}>
          <FormControlLabel control={<Switch onChange={handleClick} sx={{ opacity:0.7, marginTop: -2, marginRight:-3}}  /> } />
            <DarkModeIcon color={theme.state.darkMode ? 'primary' : 'disabled'}/>
            <LightModeIcon sx={{marginRight:1}} color={theme.state.darkMode ? 'disabled' : 'primary'}/>
        </Box>                  
        
    
  )
}

export default Toggle
import logo from './logo.svg';
import './App.css';
import HomeScreen from './screens/HomeScreen';
import FAQScreen from './screens/FAQScreen';
import ContactScreen from './screens/ContactScreen';
import ProductScreen from './screens/ProductScreen';
import { createTheme, ThemeProvider } from '@mui/material';
import { useContext, useState } from 'react';
import { ThemeContext } from './context';
import Toggle from './components/toggle/Toggle';
import {
  BrowserRouter as Router,
  Routes,
  Route,  
} from "react-router-dom";
import CartScreen from './screens/CartScreen';

function App() {
  const theme = useContext(ThemeContext);
  //console.log('theme: ', theme)
  const darkMode = theme.state.darkMode;    

  const [mode, setMode] = useState('light');
  const darkTheme = createTheme({
    palette:{
      mode: mode,    
      primary: {
        main: '#7595C6',
      }, 
      secondary:{
        main: '#cbc1b2'
      } 
    },
  });

  return (
    
      <Router>        
        <ThemeProvider theme={darkTheme}>  
        <div style={{backgroundColor: darkMode? '#222' : 'white', color: darkMode && 'white' }}>
            <Toggle setMode={setMode} mode={mode} />
            <Routes>
              <Route path="/Carro" element={<CartScreen />}/>              
              <Route path="/Contacto" element={<ContactScreen />}/>              
              <Route path="/Preguntas-frecuentes" element={<FAQScreen />}/>                              
              <Route path="/" element={<HomeScreen/>}/>
              <Route path='/Camaras/*' element={<ProductScreen />} />                           
              <Route path='*' element={<HomeScreen />} />                           
            </Routes>          
        </div>    
        </ThemeProvider>
      </Router>
  );
}

export default App;

import { Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import StoreIcon from '@mui/icons-material/Store';
import { ThemeContext } from '../context';

const Step3 = () => {
  const  theme = useContext(ThemeContext);
    // const deleteCart = () => {
    //     theme.dispatch({ type: 'CLEAR_CART' });
    //   }
  
  useEffect(() => {
    console.log('clearing cart...')
    theme.dispatch({ type: 'CLEAR_CART' });
  },[theme])

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <StoreIcon fontSize='large' color='primary' />
      <Typography style={{textAlign: 'center', fontWeight:'bold'}} > Gracias por su compra   </Typography>
      <Typography style={{textAlign: 'center'}} >Por favor siga las instrucciones recibidas en su correo para realizar el pago.</Typography>
    </div>
  )
}

export default Step3

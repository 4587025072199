import { IconButton, Paper, Typography } from '@mui/material'
import React, { useContext } from 'react'
import SmallImage from './SmallImage'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { ThemeContext } from '../context';

const CartItem = ({title, image, price, quantity}) => {
    const  theme = useContext(ThemeContext);
    const handleBuyClick = () => {
        console.log('BUY CLICKED price: ', price);
        theme.dispatch({ type: 'ADD_TO_CART', payload: { title, price, imageSrc: image } });
      }
    const handleDeleteClick = () => {
        //console.log('DELETE CLICKED');
        theme.dispatch({ type: 'REMOVE_FROM_CART', payload: { title, price, imageSrc: image } });
      }
  return (
    <div>
    <Paper elevation={5} sx={{width:'25vh' , borderRadius:2, minHeight: 220, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
        <div>
            <SmallImage src={image} alt={title} style={{ borderRadius: 20, objectFit: 'contain', width: '15vh', height:'15vh',    }} />
            <Typography style={{fontWeight:'bold', textAlign:'center'}} >1 X {title}</Typography>
            <Typography style={{ textAlign:'center'}} >{price}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem',  }}>
            <IconButton onClick={handleDeleteClick} >
                <RemoveShoppingCartIcon style={{cursor:'pointer'}} />
            </IconButton>
            <IconButton onClick={handleBuyClick} >
                <AddShoppingCartIcon style={{alignSelf:'flex-end', cursor:'pointer'}}/>
            </IconButton>
        </div>
    </Paper>
</div>
  )
}

export default CartItem

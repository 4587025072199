import React from "react";
import Product from "../components/Product";
import Navbar from "../components/Navbar";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import UsbIcon from '@mui/icons-material/Usb';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import PowerIcon from '@mui/icons-material/Power';

const products = [
  {
    id: 10,
    title: "Brinno ASP1000-P",
    model: "ASP1000-P",
    image: require("../assets/ASP1000.jpg"),
    price: '$595.000',
    description: (
      <div>
        <Typography fontSize={14} mt={1}>
        El panel solar del usb es compatible con las cámaras de seguridad recargables de la batería del puerto USB-C micro. El panel solar es compatible con bcc2000 bcc2000 Plus, tlc2000, tlc130, tlc120 con carcasa de energía ath2000.
        </Typography>
        <Typography fontSize={14} mt={1} fontWeight={"bold"}>
          
        </Typography>
        <Typography fontSize={14}>
          Fácil instalación: montar el montaje de pared ajustable en la pared y ajustar el panel solar al mejor ángulo para obtener mucha luz solar sin gastar esfuerzo para cargar la cámara inalámbrica con frecuencia.
        </Typography>
        <Typography fontSize={14} mt={1} >
          Alto suministro solar efectivo: tasa de conversión del 20%. El panel solar de 10w 5V DC está hecho de material de silicio monocristalino estable y eficiente, duradero y duradero, proporcionando energía solar continua para su Brinno Cámaras de timelapse.
        </Typography>
        <Typography fontSize={14} mt={1}>
        IP65 impermeable: El panel solar para la cámara de seguridad con IP65 impermeable. Puede soportar diferentes condiciones climáticas, trajes para exteriores, sin miedo en el viento y la lluvia.
        </Typography>
        <Typography fontSize={14} mt={1}>
        Dimensiones: 214 x 262 x 20 mm
        </Typography>
        <Typography mt={1}>Contenido del paquete:</Typography>
        <List dense>
          <ListItem>
          <ListItemAvatar>
            <Avatar>
              <SolarPowerIcon />
            </Avatar>
          </ListItemAvatar>
            <ListItemText primary="Panel Solar 10W" />
          </ListItem>
          <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BatteryChargingFullIcon />
            </Avatar>
          </ListItemAvatar>
            <ListItemText primary="Kit batería recargable" />
          </ListItem>
          <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AlignHorizontalRightIcon />
            </Avatar>
          </ListItemAvatar>
            <ListItemText primary="Soporte de montaje en pared ajustable" />
          </ListItem>
          <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PowerIcon />
            </Avatar>
          </ListItemAvatar>
            <ListItemText primary="Adaptador externo de poder" />
          </ListItem>
        </List>
       
      </div>
    ),
    imageList: [require("../assets/ASP10002.webp"), require("../assets/ASP10003.jpeg"), require("../assets/ASP10007.webp"), require("../assets/ASP10005.webp"), require("../assets/ASP10006.webp"),]
  },
  {
    id: 9,
    title: "Tascam DR-40X",
    model: "DR-40X",
    image: require("../assets/dr40x.jpg"),
    price: '$260.000',
    description: (
      <div>
        <Typography fontSize={12} mt={1}>
        Grabadora portátil para músicos, cineastas DSLR, podcasting y grabación de campo
        Entradas combinadas XLR/TRS (con alimentación fantasma) para sus propios micrófonos o instrumentos
        Micrófonos de condensador dual para grabación estéreo A/B o XY
        Hasta 17.5 horas de funcionamiento con 2 pilas AA
        </Typography>
        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
          
        </Typography>
        <Typography fontSize={12}>
        Para podcasts, efectos de sonido y aplicaciones de grabación de música, la grabadora portátil portátil Tascam DR-40X es capaz de capturar hasta cuatro pistas de audio de alta resolución sin necesidad de micrófonos externos. Sus micrófonos condensadores unidireccionales integrados pueden configurarse para una imagen estéreo amplia o ajustada, y pueden usarse simultáneamente con las entradas combinadas de micrófono / línea. Igualmente hábil como dispositivo portátil o cuando se usa en configuraciones montadas en soporte o cámara, el DR-40X puede manejar cualquier cosa, desde sesiones de muestreo y entrevistas hasta podcasts y conciertos en vivo.
        Los métodos de alimentación flexibles (baterías, alimentación del bus USB o un adaptador de CA) hacen que el DR-40X sea muy adecuado para la grabación en movimiento, grabaciones de video durante todo el día y la producción de audio basada en computadora. ¿Necesita parchear micrófonos externos o un mezclador externo? Las dos entradas balanceadas XLR-1/4 "del DR-40X admiten señales de nivel de línea o micrófono (incluso +4 dBu de consolas profesionales) y pueden suministrar alimentación fantasma si es necesario. Ya sea que use los micrófonos incorporados o una fuente externa , los modos seleccionables, como la grabación de pistas de seguridad estéreo, la reducción de picos, la limitación y el nivel automático, le ofrecen opciones para lograr una grabación limpia y sin distorsiones.
        </Typography>
        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
          Micrófonos unidireccionales ajustables
        </Typography>
        <Typography fontSize={12}>
        Los micrófonos de condensador integrados del DR-40X le permiten capturar en estéreo o 2 x captura en estéreo cuando se usa con las entradas de micrófono / línea. Para las fuentes que producen niveles de presión sonora de hasta 125 dB, los conciertos ruidosos no serán un problema. Mientras que el DR-05X tiene micrófonos omnidireccionales en una configuración AB fija, el DR-40X presenta micrófonos unidireccionales ajustables. Los micrófonos unidireccionales minimizan la captación de sonido que ingresa desde la parte posterior de las cápsulas, lo que resulta en un tono más enfocado con menos reflejos.
        </Typography>
        <Typography fontSize={12} mt={1}>
        Gire fácilmente las cápsulas entre dos configuraciones de micrófono para complementar su fuente de sonido: use la posición AB (cápsulas hacia afuera) para producir una imagen estéreo amplia, o cambie a la posición XY (cápsulas hacia adentro) si necesita un campo estéreo más ajustado con fuerte presencia central y menos diferencias de fase.
        </Typography>

        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
        Entradas de micrófono / línea XLR-1/4 "duales con alimentación fantasma
        </Typography>
        <Typography fontSize={12}>
        Las tomas combinadas Dual Neutrik XLR-1/4 "TRS admiten nivel de micrófono y nivel de línea de +4 dBu, lo que le permite grabar micrófonos externos o salidas balanceadas desde un mezclador o consola FOH. Con alimentación fantasma conmutable de 24 V o 48 V y amplia ganancia, el micrófono los preamplificadores pueden servir a una variedad de micrófonos, como los tipos dinámicos, de cinta o de condensador. Con los micrófonos integrados, puede capturar el sonido ambiental de una audiencia mientras graba directamente desde una consola externa, lo que es ideal para presentaciones en vivo.
        </Typography>
       
      </div>
    ),
    imageList: [require("../assets/dr40x.jpg"), require("../assets/dr40x2.jpg"), require("../assets/dr40x3.jpg"), require("../assets/dr40x4.jpg"), require("../assets/dr40x5.jpg"),]
  },
  {
    id: 0,
    title: "Brinno BCC2000 Plus",
    model: "BCC2000 Plus",
    image: require("../assets/bcc200plus.jpg"),
    price: '$1.599.000',
    description: (
      <div>
        <Typography fontSize={12} mt={1}>
          Tenga un control total de los ajustes de su cámara mientras mantiene
          sus pies firmemente en el suelo. El BCC2000Plus hace que el uso de una
          cámara Brinno sea fácil y eficiente. Realice todas las funciones
          comunes de la cámara al instante sin necesidad de manipular las
          tarjetas SD o la pantalla LCD de la cámara.
        </Typography>
        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
          Acceso sin escaleras:
        </Typography>
        <Typography fontSize={12}>
          La seguridad en la obra es esencial para cualquier proyecto de
          construcción. Con la BCC2000Plus, podrá acceder a su cámara sin
          necesidad de escaleras. El kit de ampliación de la cámara (AFB1000)
          elimina la necesidad de subirse para acceder a la cámara. Conéctese
          sin esfuerzo a su ordenador portátil o tableta (sistema operativo
          Windows de 64 bits) utilizando el cable de 32 pies y obtenga acceso
          instantáneo a su cámara para descargar archivos, ajustar el intervalo
          de captura, la configuración de la imagen, programar, iniciar y
          detener la grabación. Incluso puede comprobar la duración de la
          batería de la cámara y la memoria restante de la tarjeta SD.
        </Typography>
        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
          Centro de mando:
        </Typography>
        <Typography fontSize={12}>
          El miedo a chocar accidentalmente y cambiar la posición de la cámara,
          a mitad del time lapse, es cosa del pasado. Brinno Command Center es
          una plataforma de mando y control todo en uno que le permite acceder a
          su cámara a través de su ordenador portátil o tableta mediante el kit
          de extensión de cámara (AFB1000). Utilice la plataforma para realizar
          una gran variedad de acciones, como: previsualizar el encuadre de la
          cámara, cambiar los ajustes, comprobar el estado de la batería y de la
          tarjeta SD. Hágalo todo desde la comodidad de su portátil o tableta.
        </Typography>
        <Typography fontSize={12} mt={1}>
          Acceda a nuestra plataforma de control y mando de la cámara todo en
          uno, el Brinno Command Center (sistema operativo Windows de 64 bits).
          Previsualice el encuadre de la cámara, cambie los ajustes, compruebe
          el estado de la batería y de la tarjeta SD, ¡y mucho más!
        </Typography>
        <Typography fontSize={12} mt={1}>
          Junto con la duración de la batería sin preocupaciones de la carcasa
          de alimentación (ATH2000) y la capacidad del kit extensor de
          conectarse a la alimentación externa, la BCC2000 Plus es la mejor
          solución para la filmación a largo plazo.
        </Typography>
        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
          Carcasa de alimentación IPX5:
        </Typography>
        <Typography fontSize={12}>
          La carcasa de alimentación impermeable IPX5 (ATH2000) hace posible el
          time lapse a largo plazo en exteriores. Además de proporcionar
          protección contra los elementos durante las cuatro estaciones del año,
          la ranura para la batería 16AA de la carcasa alimentará su cámara
          hasta 328 días utilizando un intervalo de captura de 5 minutos.
        </Typography>
        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
          Abrazadera de grado industrial
        </Typography>
        <Typography fontSize={12}>
          Diseñado para satisfacer los requisitos de durabilidad extrema de la
          filmación en entornos industriales y de construcción, el soporte de
          abrazadera (ACC1000) puede instalarse de forma segura en una amplia
          variedad de superficies. El exclusivo diseño de montaje en varios
          puntos permite ajustar la abrazadera, según sea necesario, para
          obtener el ángulo de visión perfecto.
        </Typography>
        <Typography fontSize={12} mt={1} fontWeight={"bold"}>
          Incluye:
        </Typography>
        <Typography fontSize={12}>                              
          Cámara Brinno TLC2000. <br />
          Abrazadera de grado industrial ACC1000. <br />
          Carcasa de alimentación ATH2000. <br />
          Cable para software camera extender Kit AFB1000
        </Typography>
      </div>
    ),
    imageList: [require("../assets/bcc200plus.jpg"), require("../assets/BCC2000Plus2.jpg"), require("../assets/BCC2000Plus3.jpg"), require("../assets/BCC2000Plus5.jpg"), require("../assets/BCC2000Plus6.jpg"),]
  },
  {
    id: 1,
    title: "Cámara Timelapse TL2300",
    model: "TL2300",
    image: require("../assets/tl2300.jpg"),
    price: '$268.100',
    description: (
      <div>
        <Typography >
          Excelente cámara rápida con gran duración de batería y resistente al
          agua y polvo (IP66)
        </Typography>
        <Typography > Pantalla LCD: 2,4 "TFT LCD</Typography>
        <Typography >
          Lente: Lente de luz de estrellas ángulo de visión: 110 °
        </Typography>
        <Typography >
          Chip fotosensible: Luz de estrellas 2 megapíxeles,/2,7"
        </Typography>
        <Typography >Resolución: 1920x1080 / 1280x720</Typography>
        <Typography >Distancia de disparo: 60CM ~ infinito</Typography>
        <Typography >
          {" "}
          Luz suplementaria: un solo LED blanco de 120 ° 2W habilitará la luz
          suplementaria solo cuando el usuario necesite completamente oscura
        </Typography>
        <Typography >
          Personalice el ciclo de disparo: configure el tiempo de disparo de
          forma flexible de acuerdo con la semana y la hora
        </Typography>
        <Typography >
          {" "}
          Disparo en bucle: encendido/apagado; (cuando se enciende, el documento
          más antiguo se eliminará cuando la tarjeta esté llena)
        </Typography>
        <Typography >
          Compensación de exposición: + 3,0 EV ~-3,0 EV en incrementos de 1.0EV
        </Typography>
        <Typography >
          Formato de archivo: Fotos: JPG, vídeo: AVI
        </Typography>
        <Typography >Fuente de alimentación: 12 pilas AA</Typography>
        <Typography >
          {" "}
          Duración de la batería: 6 meses (tomar una foto a intervalos de 5
          minutos, 288 fotos/día, 8640 fotos/mes)
        </Typography>
        <Typography >
          Medios de almacenamiento: tarjeta TF, (hasta 512GB, Clase 10 o
          superior)
        </Typography>
        <Typography >* Interfaz de PC: Micro USB 2,0</Typography>
        <Typography >
          Método de instalación: soportes, correas, cuerdas
        </Typography>
        <Typography >Tamaño del paquete: 23x16x10cm</Typography>
        <Typography >Peso: 0,96 kg</Typography>
      </div>
    ),
    imageList: [require("../assets/tl2300.jpg"), require("../assets/TL23002.jpg"), require("../assets/TL23003.jpg"), require("../assets/TL23004.jpg"), require("../assets/TL23005.jpg"),]
  },
  {
    id: 2,
    title: "Brinno TLC130",
    model: "TLC130",
    image: require("../assets/tlc130.jpg"),
    price: '$561.210',
    description: (
      <Box>
        <Typography>
          La Brinno TLC130 Cámara Secuencial WiFi Portátil es pequeña,
          inteligente y lo suficientemente potente para capturar momentos
          importantes. Mientras la filmación secuencial te da vídeos geniales de
          tu jornada en general, la nueva herramienta de Step Video da los
          detalles destacados de la jornada mientras te sumerge en bonitos
          paisajes. Con la nueva app fácil de usar, puedes ver una pre
          visualización de un vídeo, editar un vídeo y compartir de manera
          rápida y sin esfuerzo un vídeo con tus amigos.
        </Typography>
        <Typography>
          La tecnología patentada te proporciona clips secuenciales sin
          problemas, inmediatamente tras cada grabación de vídeo. El sistema de
          ahorro de energía adjunta fotografías que se han sacado en unos pocos
          días en una experiencia que dura unos cuantos fantásticos minutos. El
          Modo Secuencial asegura que la cámara saca una serie de fotografías en
          intervalos seleccionados y luego las adjunta inmediatamente en un
          corto vídeo dinámico. Las bonitas opciones escénicas te dan la
          sensación de que te vas de vacaciones. Deja que Step Video revela el
          increíble escenario que te rodea.
        </Typography>
        <br />
        <Typography>
          Características de la Brinno TLC130 Cámara Secuencial WiFi Portátil:
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText>Resolución: Full HD (1080p).</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Apertura: F 2.0</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Campo de visión: 138 °</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Modos de filmación: Secuencial, Step Video, Foto.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Opciones de almacenamiento: Micro SD, Micro SDHC, Micro SDXC
              (hasta 64 GB).
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Fuente de alimentación: batería Li-PO recargable de 1350 mAh.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Vida de batería: Hasta 12 horas con un intervalo de 2 segundos /
              Hasta 16 días con un intervalo de 5 minutos.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              Conectividad: Bluetooth 4.0 (BLE) + Wi-Fi (802.11n).
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>Dimensiones: 55 x 55 x 28.2 mm.</ListItemText>
          </ListItem>
        </List>
        <Typography>Contenido del paquete:</Typography>
        <List dense>
          <ListItem>
          <ListItemAvatar>
            <Avatar>
              <VideoCameraBackIcon />
            </Avatar>
          </ListItemAvatar>
            <ListItemText primary="Brinno TLC130 Cámara Secuencial WiFi Portátil" />
          </ListItem>
          <ListItem>
          <ListItemAvatar>
            <Avatar>
              <UsbIcon />
            </Avatar>
          </ListItemAvatar>
            <ListItemText primary="Cable USB-C" />
          </ListItem>
          <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AutoStoriesIcon />
            </Avatar>
          </ListItemAvatar>
            <ListItemText primary="Guía de inicio rápido" />
          </ListItem>
        </List>
      </Box>
    ),
    imageList: [require("../assets/tlc130.jpg"), require("../assets/TLC1302.jpg"), require("../assets/TLC1303.jpg"), require("../assets/TLC1304.jpg"), require("../assets/TLC1305.jpg"),]
  },
  {
    id: 3,
    title: "Brinno BCC200",
    model: "BCC200",
    image: require("../assets/tlc200.jpg"),
    price: '$518.529',
    description: (
      <Box>
        <p>
          Brinno BCC200 es la cámara para la construcción de alto desempeño con
          duración de bateria de 80 días, sensor HDR y modo nocturno para
          documentar proyectos de construcción, analizar cambios de ambiente y
          otras actividades de larga duración. La BCC200 ofrece videos time
          lapse excepcionales incluso en condiciones de baja iluminación, contra
          luz u oscuridad. El soporte de aleación de aluminio con mayor
          capacidad de carga facilita la operación.
        </p>
        <p>
          Sensor HDR de alto desempeño captura videos time lapse en cualquier
          condición de luz.
        </p>
        <p>
          El sensor de alto rango dinámico (HDR) produce videos asombrosos bajo
          cualquier condición de iluminación, desde baja a intensa contraluz
        </p>
        <p>Soporte de Aleación de Aluminio de Clase Industrial Aeronáutico</p>
        <p>
          El soporte de BCC200 hecho de aleación de aluminio clase industrial
          aeronáutico modificado y reforzado para soportar carga de hasta 40 Kg.
          El soporte se puede montar en diferentes superficies, desde tuberias,
          objetos cilíndricos, ramas de árboles, etc. Es fácil de instalar y muy
          estable. Podrá fijar la cámara donde desea y en el ángulo preferido
          para documentar su proyecto de construcción.
        </p>
        <p>Asombrosa Vida Util de Baterias de 80 Dias con 4 Pilas AA</p>
        <p>
          La cámara posee el récord mundial de duración de bateria con
          asombrosos de 2.5 meses (80 días), utilizando un intervalo de tiempo
          de 30 minutos y 4 pilas AA. La instalación de la cámara es rápida y
          sencilla en cualquier sitio de trabajo sin necesitar cableado extra o
          energía.
        </p>
        <p>
          Modo Video Time Lapse Profesional con Alta Sensibilidad a las
          Condiciones de Poca Luz
        </p>
        <p>
          La BCC200 esta equipado con sensor de gran tamaño de pixel de 4.2µm,
          de gran desempeño incluso en condiciones de baja iluminación en donde
          otras cámaras pierden detalles
        </p>
        <p>Ángulo de Visión de 112º</p>
        <p>
          La camera Brinno BCC200 permite capturar una panoramica del lugar de
          construccion de forma facil y simple. Con un campo visual de 112º se
          captura una área mas extensa de su proyecto.
        </p>
        <p>
          Una Camara para Exteriores que Pude Trabajar en Casi Cualquier
          Condicion Climatica
        </p>
        <p>
          La carcaza resistente a la intemperie con grado de protección IPX4
          permite grabar cualquier proyecto en condiciones outdoor e indoor.
        </p>
        <p>
          Creación de Videos Time Lapse de Construcción en 3 Pasos, una Facil
          Solucion
        </p>
        <p>
          Instalación sencilla y fácil de usar en cualquier lugar. No se
          requiere cableado. Con solo presionar un solo botón se producen videos
          listos para ver, no se requiere horas de post proceso en computador.
          Brinno BCC200 hace los videos time lapse increiblemente fácil de
          crear, no se requiere habilidad técnica especialista.
        </p>
        <p>Conozca la Camara Time Lapse Brinno BCC200</p>
        <p>
          Por qué usar la Cámara de Construcción Brinno BCC200 para su Proyecto?
        </p>
        <p>
          Brinno es reconocido como líder en Cámaras Time Lapse. Con la Camara
          Profesional de Grado Industrial BCC200 Ud. tiene un equipo
          inalámbrico, a prueba de agua de fácil y rápida instalación y sin
          requerimientos de energía excesivos. Con la BCC200 usted puede
          documentar todo su proyecto sin importar cuantos días, meses o años
          tome, en solo unos minutos un video Time Lapse HDR puede presentar su
          proyecto completo con un alto grado de publicidad y manejo de
          marketing.
        </p>
      </Box>
    ),
    imageList: [require("../assets/BCC2002.jpg"), require("../assets/BCC2003.jpg"), require("../assets/BCC2004.jpg"), require("../assets/BCC2005.jpg"),]
  },
  {
    id: 4,
    title: "Brinno BCC2000",
    description: (
      <Box>
        <p>
        Construcción y cámara de lapso de tiempo al aire libre: 1 año de duración de la batería. Ideal para proyectos de construcción al aire libre a largo plazo y vigilancia de seguridad donde no se necesita Wi-Fi. Incluye cámara 1080P, carcasa impermeable y abrazadera que hacen que el monitoreo sea muy fácil.
        </p>
        <p>
        Configúralo y olvídalo: instalación rápida Utiliza el temporizador diario y la configuración de programación para grabar videos HDR de lapso de tiempo que están listos para compartir con solo pulsar un botón. La configuración inalámbrica es perfecta para lugares de trabajo o áreas fuera de la red y proporciona privacidad de los datos.
        </p>
        <p>
        Más protección y potencia: espaciosa funda resistente a la intemperie con espacio para 16 baterías adicionales que garantiza que tus proyectos se graben hasta 1 año con máxima protección. La carcasa incluye un soporte CS para lentes intercambiables y compatibilidad con paneles solares.
        </p>
        <p>
          Soporte de Aleación de Aluminio de Clase Industrial Aeronáutico
        </p>
        <p>
        Abrazadera industrial: resistente a la corrosión, la abrazadera de acero inoxidable tiene un "brazo mágico" que extiende el rango de la abrazadera para ajustar a grandes superficies en un sitio de proyecto. La rótula giratoria de 360° con inclinación de 90° te permite disparar desde cualquier ángulo deseado.
        </p>
        
      </Box>
    ),
    image: require("../assets/bcc2000.jpg"),
    price: '$1.289.000',
    imageList: [require("../assets/BCC2OOO.jpg"), require("../assets/BCC2OOO2.jpg"), require("../assets/BCC2OOO3.jpg"), require("../assets/BCC2OOO4.jpg"), require("../assets/BCC2OOO5.jpg"),]
  },
  {
    id: 5,
    title: "Brinno BCC300",
    model: "BCC300",
    image: require("../assets/bcc300.jpg"),
    price: '$663.100',
    description: (
      <Box>
        <p>
        Fácil de usar: TLC300 es la nueva cámara de lapso de tiempo 2023 de Brinno. Esta solución profesional de cámara de lapso de tiempo está diseñada para lugares de trabajo, proyectos al aire libre y aplicaciones multiusos. La función de lapso de tiempo es automática y produce video de lapso de tiempo real, no una serie de imágenes. La cámara está equipada con un campo de visión de 118° y una tarjeta Micro SD que soporta hasta 128 GB. La interfaz de usuario multilingüe está disponible en 8 idiomas y ofrece una experiencia de usuario agradable para todos los clientes
        </p>
        <p>
        Funcionamiento directo: Brinno ha mejorado su interfaz de usuario para usuarios profesionales y de uso general por igual. Solo tendrás que configurar tu "intervalo" y "horario" antes de presionar OK para comenzar a grabar. La nueva función de horario te permite elegir días y tiempos de filmación para que solo se grabe durante las horas de trabajo. La pantalla LCD IPS de cristal de 1.44 pulgadas situada en la parte posterior de la cámara de lapso de tiempo garantiza que puedas comprobar el ángulo de visión sin necesidad de echar un segundo vistazo. Fácil de usar.
        </p>
        <p>
        Bajo consumo de energía: nunca te preocupes por la energía de la batería de nuevo al disparar proyectos a largo plazo. El nuevo paquete de cámara de lapso de tiempo BCC300C de Brinno solo requiere 4 pilas AA para ahorrar energía y ahorrar presupuesto. La batería dura hasta 100 días utilizando un intervalo de captura de 5 minutos.
        </p>
        <p>
          Calidad de imagen explícita: la serie TLC300 hereda los profesionales de la serie anterior BCC200, pero incluye actualizaciones a la resolución que mejoran aún más la calidad de la imagen. Con Full HD 1080P y la función HDR habilitada, puedes grabar videos de lapso de tiempo de día a noche. Puedes crear videos de lapso de tiempo tú mismo. Desde remodelaciones hasta plantación y jardinería, hasta proyectos de bricolaje, esta solución de lapso de tiempo es perfecta para cualquier tipo de proyecto. Incluye lente intercambiable.
        </p>
        <p>
          Recibirás una cámara de lapso de tiempo TLC300, carcasa impermeable (ATH120), abrazadera, 2 cuerdas elásticas, 4 pilas AA, tarjeta SD, cubierta de lente y un manual de guía rápido.
        </p>        
      </Box>
    ),
    imageList: [require("../assets/bcc300.jpg"), require("../assets/bcc3002.jpg"), require("../assets/bcc3003.jpg"), require("../assets/bcc3004.jpg"), ]
  },
  {
    id: 6,
    title: "Brinno AFB1000",
    model: "AFB1000",
    image: require("../assets/AFB1000.jpg"),
    price: '$226.100',
    description: (
      <Box>
        <p>
        Kit extensor de cámara de 49 pies: aumenta tu alcance y amplía las capacidades de tu BCC2000. El nuevo kit de accesorios Brinno conecta tu cámara con una potencia ilimitada, realiza transferencias de datos más rápidas y proporciona acceso al centro de comandos Brinno.
        </p>
        <p>
        Graba y descarga simultáneamente: no hay necesidad de dejar de grabar para descargar tus archivos, el cable de transferencia USB-C de datos de alta velocidad te conecta a una fuente de alimentación ilimitada y permite grabación simultánea y transferencia de datos sin la interrupción de la filmación.
        </p>
        <p>
        Marco y disparo precisos: no más adivinar si tu cámara está enmarcada con precisión, en enfoque o grabación en lugares altos o difíciles de alcanzar. Conecta el cable de 49 pies de tu cámara a tu computadora y obtén acceso instantáneo a la vista y grabaciones de la cámara.
        </p>
        <p>
        Resistente y duradero: con un cable recubierto UV, el cable fue diseñado para soportar el aire libre y un sitio de construcción. El kit también viene con un clip de cable para garantizar un sellado impermeable dentro de tu kit de carcasa y 10 bridas para asegurar el camino del cable.
        </p>             
      </Box>
    ),
    imageList: [require("../assets/AFB10002.jpg"), require("../assets/AFB10003.jpg"), require("../assets/AFB10004.jpg"), require("../assets/AFB10005.jpg"), ]
  },
  {
    id: 7,
    title: "Brinno ATH120",
    model: "ATH120",
    image: require("../assets/ATH120.jpg"),
    price: '$75.000',
    description: (
      <Box>
        <p>
        Funda resistente a la intemperie: La carcasa ATH120 se adapta a nuestra cámara TLC200 Pro y está diseñada para mantener su equipo seguro y protegido en prácticamente cualquier clima y cualquier lugar. Mantenga la humedad no deseada, la arena y el polvo lejos para garantizar que siempre obtenga la toma perfecta.
        </p>
        <p>
        Configúralo y olvídalo: el ATH120 protege tu cámara permitiéndote capturar imágenes magníficas. La funda está diseñada para complementar la tecnología de manos libres protegiendo tu cámara incluso en las peores condiciones climáticas.
        </p>
        <p>
        Características intuitivas: El exterior resistente a la intemperie proporciona un sellado resistente, evitando la entrada de humedad y desechos. Un poco de protección adicional contra caídas y caídas. El soporte de tornillo ¼"x20 permite que se enrosque en un trípode o abrazadera de cámara estándar.
        </p>
        <p>
        Compañero perfecto: con la libertad y flexibilidad para capturar cualquier disparo, en cualquier momento y en cualquier lugar, su creatividad puede ser completamente desatada. El ATH120 mantendrá su equipo seguro para que pueda capturar la toma perfecta o hacer una crónica de su próximo gran proyecto.
        </p>             
      </Box>
    ),
    imageList: [require("../assets/ATH120.jpg"), require("../assets/ATH1202.jpg"), require("../assets/ATH1203.jpg"), require("../assets/ATH1204.jpg"), ]
  },
  {
    id: 8,
    title: "Brinno SHC500",
    model: "SHC500",
    image: require("../assets/SHC500.jpg"),
    price: '$150.000',
    description: (
      <Box>
        <p>
        Ajuste para mirillas de 14mm: grosor de puerta aplicable de 43mm in a 80mm. <br/> 
        </p>
        <p>
        Diseño discreto oculto: a diferencia de las cámaras de video generales, la cámara Brinno Peephole es completamente invisible y oculta desde el exterior. Puedes ver a tus visitantes a través de la pantalla LCD de 2.7 pulgadas, no hay necesidad de ver a través del pequeño agujero abierto. Con la cámara digital Brinno te permite guardar imágenes sin dejar saber a tus visitantes o vecindarios. Además, este discreto agujero espía para puerta delantera evitará el posible robo para vandalizar tu dispositivo de seguridad doméstico.
        </p>
        <p>
        No cambia la apariencia de la puerta delantera: instalación fácil y sencilla en 3 pasos. Simplemente conecta el SHC500 a tu mirilla preexistente y listo. No se necesitan herramientas adicionales durante la instalación.
        </p>
        <p>
        Funciona con pilas durante 6 meses: el sistema de control de energía de Brinno te proporciona hasta seis meses de energía (aprox. 3.000 disparadores) antes de necesitar cambiar las baterías 4AA.
        </p>             
        <p>
        El paquete incluye: cámara de mirilla SHC500, brida de base, orificio Ø14, barril de mirilla, 4 pilas AA, manual de usuario y guía rápida.
        </p>             
      </Box>
    ),
    imageList: [require("../assets/SHC500.jpg"), require("../assets/SHC5002.jpg"), require("../assets/SHC5003.jpg"), require("../assets/SHC5004.jpg"), require("../assets/SHC5005.jpg"), ]
  },
];
function ProductScreen(props) {
  const location = useLocation();

  // const { title } = props?.location?.state;
// //console.log('props?.location: ', location?.state?.title)
  return (
    <div>
      <Navbar />
      {/* {products.filter(item => item.title === 'Brinno BCC200')} */}
      {products.filter((item, index) => item.title === location?.state?.title).map((item, index) => (
        <Product              
          imageSrc={item.image}
          title={item.title}
          description={item.description}
          manufacturer={item.model === "TL2300" || item.model === "DR-40X" ? "" : "Brinno"}          
          price={item.price}
          availability="En Stock"
          key={item.id}
          avatarSource={require("../assets/brinno.png")}
          imageList={item.imageList}
          model={item.model}
        />
      ))}
      <Footer />
    </div>
  );
}

export default ProductScreen;

import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const FormContainer = styled("div")({
  display: "flex",
  justifyContent: "center", // Center along horizontal axis
});

const Form = styled("form")({
  width: "50%",
});

const validatePhone = (value) => {
    const regex = /^(\+56[239])?\d{8}$|^(\+56[239])?\d{11}$|^\+56[239]\d{12}$/;
    return regex.test(value);
  };
  
  

const CartForm = ({setSnackBarOpen, formData, setFormData, setDisabledNext}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [phoneError, setPhoneError] = useState(false)
  const [emailError , setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)  
  const [phoneHelp, setPhoneHelp] = useState(false)
  const [sent , setSent]  = useState(false)
  const formRef = useRef();

  function isAnyFieldEmpty(formData) {
    // //console.log('see if empty')
    for (let key in formData) {
        if (formData.hasOwnProperty(key) && formData[key] === '') {
            return true;
        }
    }
    return false;
  }
  // //console.log('is empty??' , isAnyFieldEmpty(formData));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(name === 'phone' && !phoneError ){
      setPhoneHelp('Empezando con')
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    //console.log(formData)
  };
  //console.log('setPhoneError', phoneError)
  useEffect(() => {
    if(phoneError || emailError || nameError || isAnyFieldEmpty(formData)){
      //console.log('DISABLING')
      setDisabledNext(true)
    }else{
      setDisabledNext(false)
    }
  },[phoneError, emailError, nameError, formData])

  const handleBlurPhone = () => {
    setPhoneHelp(false)
    setPhoneError(!validatePhone(formData.phone))    
  }

  const handleBlurEmail = () => {
    setEmailError(!isEmail(formData.email))
  }

  const handleBlurName = () => {
    setNameError(formData.name.length < 5)
  }

  function isEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValidRut(rut) {
    // Remove any dots and dashes and convert to uppercase
    rut = rut.replace(/[.-]/g, '').toUpperCase();

    // Regular expression to validate RUT format
    const rutRegex = /^(\d{1,2}\.)?\d{3}\.\d{3}-[\dkK]$/;

    // Check if the format matches
    if (!rutRegex.test(rut)) {
        //console.log('hello from isValidRut')
        return false;
    }

    // Split the RUT into its number and verifier parts
    const [rutNumber, rutVerifier] = rut.split('-');

    // Calculate the verifier digit
    let sum = 0;
    let multiplier = 2;
    for (let i = rutNumber.length - 1; i >= 0; i--) {
        sum += parseInt(rutNumber.charAt(i)) * multiplier;
        multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const remainder = sum % 11;
    const calculatedVerifier = remainder === 0 ? '0' : remainder === 1 ? 'K' : (11 - remainder).toString();

    // Check if the verifier digit matches the calculated one
    return calculatedVerifier === rutVerifier;
}

// Test the function
// //console.log(isValidRut('17089672-6')); // true


// //console.log('name error: ', nameError)
  return (
    <FormContainer>        
      <Form sx={{flex: {xs:1, md:'none'}}} ref={formRef} >
        {sent && <Typography sx={{textAlign:'center', fontSize:20, fontWeight:'bold'}} >Gracias por su mensaje.</Typography>}
        <div >
          <TextField
            required
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            color={formData.name.length > 5 && 'success'}
            focused={formData.name.length > 0}
            error={nameError}
            helperText={nameError && 'Debe ingresar un nombre'}
            onBlur={handleBlurName}
            disabled={sent}
          />
          <TextField
            required
            label="RUT"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            disabled={sent}
          />
          <TextField
            required
            label="Teléfono"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            error={phoneError}
            helperText={phoneError ? 'Debe ser un número chileno' : phoneHelp ? phoneHelp : null}
            onBlur={handleBlurPhone} 
            color={validatePhone(formData.phone) && 'success'}
            focused={formData.phone.length > 0}
            disabled={sent}

                     
          />
          <TextField
            required
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            type="email"
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            color={isEmail(formData.email) ? 'success' : 'warning'}
            focused={formData.email.length > 0}
            error={emailError}
            helperText={emailError && 'Debes ingresar una email válido.'}
            onBlur={handleBlurEmail}
            disabled={sent}
          />          
          <TextField
            required
            label="Dirección"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            type="address"
            variant="filled"
            sx={{ width: "100%", my: 1 }}
            // color={isEmail(formData.email) ? 'success' : 'warning'}
            // focused={formData.address.length > 0}
            // error={emailError}
            helperText={emailError && 'Debes ingresar una dirección válido.'}
            // onBlur={handleBlurEmail}
            disabled={sent}
          />          
        </div>
        {/* <CircularIntegration handleSubmit={(event) => handleSubmitv1(event)} errors={nameError || phoneError || emailError} /> */}
      </Form>
      
    </FormContainer>
  );
};

export default CartForm;

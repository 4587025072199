import { Button, Grid, Paper, Snackbar, SnackbarContent, Typography } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import { ThemeContext } from '../context';

const Step2 = ({formData, setFormData, setActiveStep, setEmailSent, emailSent, }) => {
  const [loading, setLoading] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const { state } = useContext(ThemeContext);
  
  const total = useMemo(() => {
    return state.items.reduce((acc, item) => {
      // Remove "$" sign, dots, and commas, then parse into a number
      const price = Number(item.price.replace(/[\$\.]|,/g, ""));
      return acc + price;
    }, 0);
  }, [state.items]);

const totalFormatted = '$' + total.toLocaleString('es-ES', {
  style: 'currency',
  currency: 'CLP'
});

  async function createEmailPayment() {
    const params = {
      // subject: 'Pago de prueba',
      amount: total,
      email: formData.email,
      name: formData.name,
      phone: formData.phone,
      company: formData.company,
      address: formData.address,      
    };
    setLoading(true);
  
    try {
      const response = await fetch('https://4pzex6ivedboxwhgoigockj47q0bvhkn.lambda-url.us-east-1.on.aws/', {
        method: 'POST',        
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ABbcDDeF'
        }
      });
      //console.log('response: ', response)
      if (!response.ok) {
        throw new Error('Failed to send payment');
      }
      // const data = await response.json();
      setEmailSent(true);
      setActiveStep(3)
      // //console.log('Payment sent successfully:', data);
    } catch (error) {
      console.error('Error sending payment:', error);
      setSnackBarOpen(true)
    } finally {
      // Set loading to false whether the request succeeded or failed
      setLoading(false);
    }
  }
  
// //console.log('hola')
  return (
    <div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <SnackbarContent
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" align="center">
                Falló el envío del correo. Por favor contáctanos en contacto@tectrova.cl
              </Typography>
              <ErrorIcon style={{ marginLeft: '0.5em' }} />
            </div>
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Snackbar>
          <Typography style={{textAlign: 'center', marginTop:30}}  >Enviaremos un cobro al correo ingresado: </Typography>
          <Paper elevation={5} style={{ margin: 20, borderRadius: 10, minWidth:320 }}>
          <Grid container spacing={1} justifyContent="center" alignItems="center"  >
            <Grid item xs={6} sm={2} md={1}>
              <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Nombre:</Typography>
              <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Empresa:</Typography>
              <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Mail:</Typography>
              <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Teléfono:</Typography>
              <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Dirección:</Typography>
              <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Total:</Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={4}>
              <Typography style={{ textAlign: 'left' }}>{formData.name}</Typography>
              {formData.company && (
                <Typography style={{ textAlign: 'left' }}>{formData.company}</Typography>
              )}
              <Typography style={{ textAlign: 'left', fontWeight:'bold' }}>{formData.email}</Typography>
              <Typography style={{ textAlign: 'left' }}>{formData.phone}</Typography>
              <Typography style={{ textAlign: 'left' }}>{formData.address}</Typography>
              <Typography style={{ textAlign: 'left' }}>{totalFormatted}</Typography>
            </Grid>
          </Grid>
          <Button onClick={createEmailPayment} disabled={emailSent}  fullWidth>
            {loading ? <CircularProgress size={15} /> : null}
            {
              emailSent ? 'Email enviado' :
              loading ? 'Enviando...' : 'Enviar email'
            }
          </Button>
</Paper>
    </div>
  )
}

export default Step2

import { Box, Typography } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import CartItem from './CartItem'
import { ThemeContext } from '../context';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const Step0 = () => {
  const { state } = useContext(ThemeContext);
  const items = state.items
  const total = useMemo(() => {
    return state.items.reduce((acc, item) => {
      const price = Number(item.price.replace(/[\$\.]|,/g, ""));
      return acc + price;
    }, 0);
  }, [items]);

  const totalFormatted = '$' + total.toLocaleString('es-ES', {
    style: 'currency',
    currency: 'CLP'
});


  return (
    <div style={{paddingLeft:10}} >
      {
        items.length === 0 ?
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}} >
          <SentimentVeryDissatisfiedIcon fontSize='large' color='primary' />
          <Typography >Aún no hay productos en su carro.</Typography>
        </Box>
        :
        <React.Fragment>
          <Typography >Productos:</Typography>
          <div style={{display:'flex', flexWrap:'wrap', gap:10,   }} >
            {
              items.map((item, index) => {
                return <CartItem key={index} title={item.title} price={item.price} image={item.imageSrc} />
              })
            }
          </div>
          <div style={{flexDirection:'row', display:'flex', gap:10, marginTop:20   }} >
          <Typography style={{fontWeight:'bold'}} >Total:</Typography> 
          <Typography > {totalFormatted} IVA Incluido</Typography>
          </div>
        </React.Fragment>
      }
    </div>
  )
}

export default Step0
